var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.failedItems.length > 0)?_c('CRow',[_c('CCol',[_c('CCard',[_c('CCardHeader',[_c('h2',{staticClass:"text-danger"},[_vm._v("Escalated Tasks")])]),_c('CCardBody',[_c('CDataTable',{attrs:{"items":_vm.failedItems,"fields":_vm.failedFields,"striped":""},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('span',{class:_vm.getStatusObject(item).variant
                      ? 'text-' + _vm.getStatusObject(item).variant
                      : ''},[_c('MIcon',{attrs:{"name":_vm.getStatusObject(item).status}})],1)])]}},{key:"clientCode",fn:function(ref){
                      var item = ref.item;
return [_c('td',[_c('router-link',{attrs:{"to":{
                    name: 'view-client',
                    params: { name: item.clientCode },
                  }}},[_vm._v(_vm._s(item.clientCode))])],1)]}},{key:"title",fn:function(ref){
                  var item = ref.item;
return [_c('td',[(item.taskId)?_c('router-link',{attrs:{"to":{ name: 'view-task', params: { id: item.taskId } }}},[_vm._v(_vm._s(item.title))]):_c('div',[_vm._v(_vm._s(item.title))])],1)]}}],null,false,307285495)})],1)],1)],1)],1):_vm._e(),_c('CRow',[_c('CCol',[_c('CCard',[_c('CCardHeader',[_c('h2',[_vm._v("Current Tasks")])]),_c('CCardBody',[_c('CDataTable',{attrs:{"items":_vm.currentItems,"fields":_vm.currentFields,"striped":""},scopedSlots:_vm._u([{key:"no-items-view",fn:function(){return [_c('span',{staticClass:"text-center"},[_c('h2',[_vm._v("No Tasks")]),_c('h4',[_vm._v("Wahoo! Nothing is waiting for you.")])])]},proxy:true},{key:"clientCode",fn:function(ref){
                  var item = ref.item;
return [_c('td',[_c('router-link',{attrs:{"to":{
                    name: 'view-client',
                    params: { name: item.clientCode },
                  }}},[_vm._v(_vm._s(item.clientCode))])],1)]}},{key:"title",fn:function(ref){
                  var item = ref.item;
return [_c('td',[(item.taskId)?_c('router-link',{attrs:{"to":{ name: 'view-task', params: { id: item.taskId } }}},[_vm._v(_vm._s(item.title))]):_c('div',[_vm._v(_vm._s(item.title))])],1)]}},{key:"taskTime",fn:function(ref){
                  var item = ref.item;
return [_c('td',{staticClass:"align-middle"},[_vm._v(" "+_vm._s(_vm.formatTimestamp(item.startedAt))+" ")])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }