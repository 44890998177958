<template>
  <div>
    <CRow v-if="failedItems.length > 0">
      <CCol>
        <CCard>
          <CCardHeader>
            <h2 class="text-danger">Escalated Tasks</h2>
          </CCardHeader>
          <CCardBody>
            <CDataTable :items="failedItems" :fields="failedFields" striped>
              <template #status="{ item }">
                <td>
                  <span
                    :class="
                      getStatusObject(item).variant
                        ? 'text-' + getStatusObject(item).variant
                        : ''
                    "
                  >
                    <MIcon :name="getStatusObject(item).status" />
                  </span>
                </td>
              </template>
              <template #clientCode="{ item }">
                <td>
                  <router-link
                    :to="{
                      name: 'view-client',
                      params: { name: item.clientCode },
                    }"
                    >{{ item.clientCode }}</router-link
                  >
                </td>
              </template>
              <template #title="{ item }">
                <td>
                  <router-link
                    v-if="item.taskId"
                    :to="{ name: 'view-task', params: { id: item.taskId } }"
                    >{{ item.title }}</router-link
                  >
                  <div v-else>{{ item.title }}</div>
                </td>
              </template>
            </CDataTable>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <CCard>
          <CCardHeader>
            <h2>Current Tasks</h2>
          </CCardHeader>
          <CCardBody>
            <CDataTable :items="currentItems" :fields="currentFields" striped>
              <template #no-items-view>
                <span class="text-center">
                  <h2>No Tasks</h2>
                  <h4>Wahoo! Nothing is waiting for you.</h4>
                </span>
              </template>
              <template #clientCode="{ item }">
                <td>
                  <router-link
                    :to="{
                      name: 'view-client',
                      params: { name: item.clientCode },
                    }"
                    >{{ item.clientCode }}</router-link
                  >
                </td>
              </template>
              <template #title="{ item }">
                <td>
                  <router-link
                    v-if="item.taskId"
                    :to="{ name: 'view-task', params: { id: item.taskId } }"
                    >{{ item.title }}</router-link
                  >
                  <div v-else>{{ item.title }}</div>
                </td>
              </template>
              <template #taskTime="{ item }">
                <td class="align-middle">
                  {{ formatTimestamp(item.startedAt) }}
                </td>
              </template>
            </CDataTable>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<style lang="scss"></style>
<script>
import { mapGetters } from 'vuex';
import { formatTimestamp } from '@/utils/format';
const FAILED_TASKS = [
  {
    id: '',
    icon: 'fa-exclamation-triangle',
    client: 'CSI',
    time: 'Feb 19, 2020 10:00 AM',
    task: 'Upload the Client Requirements Intake Workbook',
    error: 'Unable to create report: No Results',
    variant: 'warning',
  },
];

const FAILED_FIELDS = [
  {
    key: 'status',
    label: '',
  },
  {
    key: 'clientCode',
    label: 'Client',
  },
  {
    key: 'phase',
    label: 'Phase',
  },
  {
    key: 'title',
    label: 'Task',
  },
  {
    key: 'reasonForIncompletion',
    label: 'Reason',
  },
  {
    key: 'taskTime',
    label: 'Escalated At',
  },
];

const CURRENT_FIELDS = [
  {
    key: 'clientCode',
    label: 'Client',
  },
  {
    key: 'phase',
    label: 'Phase',
  },
  {
    key: 'title',
    label: 'Task',
  },
  {
    key: 'taskTime',
    label: 'Scheduled At',
  },
];

export default {
  name: 'Activities',
  props: {},
  computed: {
    ...mapGetters('tasks', ['activities']),
    failedItems() {
      return this.activities.filter(
        (task) => task.status === 'FAILED' || task.status === 'ESCALATED'
      );
    },
    failedFields() {
      return FAILED_FIELDS;
    },
    currentItems() {
      return this.activities.filter(
        (task) => task.status === 'SCHEDULED' || task.status === 'IN_PROGRESS'
      );
    },
    currentFields() {
      return CURRENT_FIELDS;
    },
  },
  watch: {
    $route(to, from) {
      this.$store.dispatch('tasks/fetchAllTasks');
    },
  },
  methods: {
    formatTimestamp,
    getStatusObject(task) {
      let obj = {
        variant: null,
        status: null,
      };
      switch (task.status) {
        case 'IN_PROGRESS':
          obj.status = 'fa-arrow-circle-right';
          break;
        case 'CANCELED':
          obj.variant = 'danger';
          obj.status = 'fa-x-circle';
          break;
        case 'COMPLETED':
          obj.variant = 'success';
          obj.status = 'fa-check';
          break;
        case 'SKIPPED':
          obj.status = 'fa-list-low-priority';
          break;
        case 'ESCALATED':
        case 'FAILED':
          obj.variant = 'warning';
          obj.status = 'fa-exclamation-triangle';
          break;
        case 'SCHEDULED':
          obj.variant = 'info';
          obj.status = 'fa-clock-o';
          break;
        case 'TIMED_OUT':
          obj.variant = 'warning';
          obj.status = 'fa-warning';
          break;
        case 'READY_FOR_RERUN':
          obj.variant = 'info';
          obj.status = 'fa-sync';
          break;
      }
      return obj;
    },
  },
  data() {
    return {
      tasks: [],
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.dispatch('tasks/fetchAllTasks');
    });
  },
};
</script>
